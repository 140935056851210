import { ArrowLeft, ExclamationTriangle } from "icons/font-awesome/solid"
import { useTranslations } from "modules/translations"
import getConfig from "next/config"
import { ReactNode } from "react"
import LinkButton from "ui-kit/elements/link-button"

const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: { defaultRoute: string } }

export default function NotFoundPage (): ReactNode {
    const { translate } = useTranslations()
    return <div className="flex flex-col items-center text-gray-900">
        <span className="text-6xl"><ExclamationTriangle /></span>
        <h1 className="font-black text-3xl mb-8">
            {translate( "pages.404.header" )}
        </h1>
        <LinkButton href={publicRuntimeConfig.defaultRoute} icon={ArrowLeft}>
            {translate( "pages.404.go_back" )}
        </LinkButton>
    </div>
}
